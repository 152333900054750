.pagenotfound {
  background: #201f1f;
  align-content: center;
  justify-content: center;
  color: gainsboro;
  font-family: "Poppins";

  a {
    text-decoration: none;
    color: yellow;
  }

  h2 {
    font-size: 30px;
    text-align: right;
  }

  img {
    width: 40%;
    min-width: 40%;
  }

  button {
    background-color: white;
    display: inline-block;
    cursor: pointer;
    color: black;
    font-family: Arial;

    text-decoration: none;
  }

  button:hover {
    background-color: #f9d423;
    display: inline-block;
    cursor: pointer;
    color: black;
    font-family: Arial;

    text-decoration: none;
  }

  .position-pic {
    height: 100vh;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}

@media (min-width: 30px) {
  .pagenotfound {
    img {
      width: 40%;
    }

    h2 {
      font-size: 20px;
      text-align: right;
      text-align: center;
    }
  }

  .position-pic {
    display: flex;
    flex-wrap: wrap-reverse;
  }
}

@media (min-width: 494px) {
  .pagenotfound {
    img {
      width: 80% !important;
    }

    h2 {
      font-size: 20px;
      text-align: right;
      text-align: right;
    }
  }
  .position-pic {
    display: flex;
    flex-wrap: nowrap;
  }
}
@media (min-width: 640px) {
  .pagenotfound {
    img {
      width: 50%;
    }

    h2 {
      font-size: 20px;
      text-align: right;
      text-align: right;
    }
  }
  .position-pic {
    display: flex;
    flex-wrap: nowrap;
  }
}

@media (min-width: 1200px) {
  .pagenotfound {
    img {
      width: 40%;
    }

    h2 {
      font-size: 50px;
      text-align: right;
      text-align: right;
    }
  }
  .position-pic {
    display: flex;
    flex-wrap: nowrap;
  }
}

@import url("https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,800&display=swap");

.containers {
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  background: #201f1f;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    // width: 550px;
    // height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .title {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      height: 50px;

      .block {
        width: 0%;
        height: inherit;
        background: #e65c00; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to left,
          #f9d423,
          #e65c00
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to left,
          #f9d423,
          #e65c00
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

        position: absolute;
        animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
        display: flex;
      }

      .my-name {
        font-family: "Poppins";
        color: #fff;
        -webkit-animation: mainFadeIn 2s forwards;
        -o-animation: mainFadeIn 2s forwards;
        animation: mainFadeIn 2s forwards;
        animation-delay: 1.6s;
        opacity: 0;
        display: flex;
        align-items: baseline;
        position: relative;

        span {
          width: 0px;
          height: 0px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          background: #ffb510;
          -webkit-animation: load 0.6s cubic-bezier(0.74, 0.06, 0.4, 0.92)
            forwards;
          animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
          animation-delay: 2s;
          margin-left: 5px;
          margin-top: -10px;
          position: absolute;
          bottom: 13px;
          right: -12px;
        }
      }
    }

    .role {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      height: 86px;
      //   margin-top: -1px;

      .block {
        // width: 0%;
        // height: inherit;
        // background: #e91e63;
        // position: absolute;
        // animation: secBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
        // animation-delay: 2s;
        // display: flex;
      }

      .my-role {
        animation: secFadeIn 2s forwards;
        animation-delay: 3.2s;
        opacity: 0;
        font-weight: 400;
        font-family: "Lato";
        color: #ffffff;
        text-transform: uppercase;
        letter-spacing: 4px;
      }
    }
    .adding {
      width: 100%;
      display: flex;
      height: 86px;
      display: flex;
      justify-content: flex-start;
      .button {
        animation: secFadeIn 5s forwards;
        animation-delay: 4.2s;
        opacity: 0;
        font-weight: 400;
        font-family: "Lato";
        color: #ffffff;
        letter-spacing: 4px;
      }
    }
  }
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: #e9d856;
    border: 0px solid #ddd;
    opacity: 0;
  }
  50% {
    width: 10px;
    height: 10px;
    background: #e9d856;
    opacity: 1;
    bottom: 45px;
  }
  65% {
    width: 7px;
    height: 7px;
    bottom: 0px;
    width: 15px;
  }
  80% {
    width: 10px;
    height: 10px;
    bottom: 20px;
  }
  100% {
    width: 7px;
    height: 7px;
    background: #e9d856;
    border: 0px solid #222;
    bottom: 13px;
  }
}

@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

// button {
//   width: 350px;
//   height: 80px;
//   background: #ffb510;
//   position: absolute;
//   right: 0;
//   bottom: -80px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   animation: top 0.8s forwards;
//   animation-delay: 4s;
//   span {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 12px;
//     color: #232323;
//     font-family: "Poppins";

//     i {
//       margin-right: 25px;
//       font-size: 22px;
//       color: #232323;
//       animation: icon 2s forwards;
//       animation-delay: 4s;
//       opacity: 0;
//     }
//   }
// }

@keyframes top {
  0% {
    opacity: 0;
    bottom: -80px;
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}

@keyframes icon {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.3) rotate(-02deg);
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}

@media (min-width: 30px) {
  .my-name {
    font-size: 30px;
  }
  .my-role {
    font-size: 10px;
  }
  .role {
    margin-top: -40px;
  }
  .button {
    font-size: 10px;
  }
  .button:hover {
    text-decoration: blue;
  }
}

@media (min-width: 992px) {
  .my-name {
    font-size: 60px;
  }
  .my-role {
    font-size: 20px;
  }
  .role {
    margin-top: -30px;
  }
  .button {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .my-name {
    font-size: 80px;
  }
  .my-role {
    font-size: 30px;
  }
  .role {
    margin-top: -20px;
  }
  .button {
    font-size: 15px;
  }
}

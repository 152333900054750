@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

.jumbotron {
    width: 100%;
    height: fit-content !important;
    height: 40px !important;
    background-color: #201f1f !important;
    margin:0 auto !important;
    padding: 40px 0 20px 10px!important;
    color: black !important;
    text-align:start; 
    border-radius: 0% !important;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid #201f1f !important;
  }
  
  .jumbotron h2{
  

    font-size: 20px;
  
  }


@media (min-width: 30px) {
  .jumbotron h2{
  
    color: rgb(255, 255, 255) !important;
    font-family: "Poppins";
    font-size: 30px;
  
  }
}

@media (min-width: 992px) {
  .jumbotron h2{
  

    font-size: 40px;
  
  }
}

@media (min-width: 1200px) {
  .jumbotron h2{
  

    font-size: 40px;
  
  }
}

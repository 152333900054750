@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap");

.readme {
  background-color: #ffb510;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(30, 27, 27);
  font-family: "Montserrat", sans-serif;
}

.readme img {
  border-radius: 50%;
}

.readme p {
  padding-left: 20px;
  font-size: 30px;
}

.readme h1 {
  color: rgb(30, 27, 27);
  color: white;
}

.readme span {
  color: white;
}

.stack-t {
  text-decoration: underline;
}

.readme-info {
  margin-top: 40px;
}
.readme-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stack-style {
  background-color: rgb(48, 44, 44);
  padding: 15px;
  width: fit-content;
  text-decoration: none;
  color: gainsboro;
  margin: 1px;
  font-size: medium;
}

.stack-style:hover {
  color: rgb(182, 41, 123);
  text-decoration: none;
  cursor: pointer;
}

.stack-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  line-height: 55px;
 
  ul {
    list-style: none;
  }
}

@media (min-width: 30px) {
  .readme {
    height: fit-content;
  }
  .readme-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 20px;
  }
  .readme h1 {
    margin-top: 10px;
  }
  .readme p {
    padding-top: 10px;
    font-size: 17px;
  }
  .readme img {
    width: 180px;
  }
}

@media (min-width: 1000px) {
  .readme {
    height: fit-content;
  }
  .readme-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 20px;
  }
  .readme h1 {
    margin-top: 10px;
  }
  .readme p {
    padding-top: 38px;
    font-size: 15px;
  }
  .readme img {
    width: 330px;
  }
}

@media (min-width: 1050px) {
  .readme {
    height: fit-content;
  }
  .readme-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 20px;
  }
  .readme h1 {
    margin-top: 10px;
  }
  .readme p {
    padding-top: 38px;
    font-size: 18px;
  }
  .readme img {
    width: 350px;
  }
}

@media (min-width: 1200px) {
  .readme {
    height: fit-content;
  }
  .readme-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    margin-left: 200px;
    margin-right: 200px;
    margin-top: 20px;
  }

  .readme p {
    padding-top: 40px;
    font-size: 20px;
  }
  .readme img {
    width: 350px;
  }
}

.cta-btn {
   display: inline-block;
   position: relative;
   padding: 0.8rem 1.6rem;
   font-weight: bold;
   line-height: 1;
   z-index: 1;
   transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
 
   &::after {
     content: '';
     display: block;
     position: absolute;
     width: 0px;
     height: 100%;
     left: 0;
     bottom: 0;
     z-index: -1;
     transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
   }
 }

 .btn-box {
    padding-left: 10px;
 }
 .btn-cv {
   color: #292828;
   border: 2px solid #292828;
   margin: 10px;
   padding: 10px;
   width: 200px;
   text-align: center;
   
 
   &::after {
     background: #292828;
   }
 
   &:hover {
    color:rgb(175, 28, 84);
     background-color: rgb(0, 0, 0);
     text-decoration: none;
 
     &::after {
       width: 100%;
     }
   }
 }

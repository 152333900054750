footer {
    background-color: #201f1f;
    color: gainsboro;
}

footer a{
    color: gainsboro;
}

footer a:hover{
    color: gainsboro;
}

@media (min-width: 30px) {
  footer {
      font-size: 12px;
  }
  }
  
  @media (min-width: 992px) {
    footer {
        font-size: 16px;
    }
  }
  
  